<template>
  <b-card
      class="allotoutboundinbound-edit-wrapper"
  >
    <!-- form -->
    <b-form id="allotoutboundinboundForm" class="edit-form mt-2">
      <!--  基本信息    -->
      <b-card header="基本信息">
        <b-row>
          <!--    出库编号      -->
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="入库编号"
                label-for="outbound_no"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="outbound_no"
                  size="sm"
                  readonly
                  v-model="allotoutboundinbound.inbound_no"
              />
            </b-form-group>
          </b-col>
          <!--    调拨类型      -->
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="调拨类型"
                label-for="allot_type"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="allot_type"
                  size="sm"
                  readonly
                  v-model="allotoutboundinbound.allot_type"
              />
            </b-form-group>
          </b-col>
          <!--    责任人      -->
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="责任人"
                label-for="duty_name"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="duty_name"
                  size="sm"
                  v-model="allotoutboundinbound.duty_name"
                  readonly
              />
            </b-form-group>
          </b-col>
          <!--    调拨日期      -->
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="调拨日期"
                label-for="allot_time"
                label-size="sm"
                class="mb-1 required"

            >
              <b-form-input
                  id="allot_time"
                  size="sm"
                  v-model="allotoutboundinbound.allot_time"
                  readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <!--  调出仓库    -->
      <b-card header="调出仓库">
        <b-row>
          <!--调出仓库          -->
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="调出仓库"
                label-for="fromwarehouse_name"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="fromwarehouse_name"
                  size="sm"
                  v-model="allotoutboundinbound.fromwarehouse_name"
                  readonly
              />
            </b-form-group>
          </b-col>
          <!-- 联系人         -->
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="联系人"
                label-for="from_contact"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="from_contact"
                  size="sm"
                  v-model="allotoutboundinbound.from_contact"
                  readonly
              />
            </b-form-group>
          </b-col>
          <!-- 联系电话         -->
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="联系电话"
                label-for="from_mobile"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="from_mobile"
                  size="sm"
                  v-model="allotoutboundinbound.from_mobile"
                  readonly
              />
            </b-form-group>
          </b-col>
          <!-- 仓库地址         -->
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="仓库地址"
                label-for="from_location"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="from_location"
                  size="sm"
                  v-model="allotoutboundinbound.from_location"
                  readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <!--  调入仓库   -->
      <b-card header="调入仓库">
        <b-row>
          <!--   调入仓库       -->
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="仓库"
                label-for="towarehouse_name"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="towarehouse_name"
                  size="sm"
                  v-model="allotoutboundinbound.towarehouse_name"
                  readonly
              />
            </b-form-group>
          </b-col>
          <!--    联系人      -->
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="联系人"
                label-for="to_contact"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="to_contact"
                  size="sm"
                  v-model="allotoutboundinbound.to_contact"
                  readonly
              />
            </b-form-group>
          </b-col>
          <!--    联系电话       -->
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="联系电话"
                label-for="to_mobile"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="to_mobile"
                  size="sm"
                  v-model="allotoutboundinbound.to_mobile"
                  readonly
              />
            </b-form-group>
          </b-col>
          <!--    仓库地址      -->
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="仓库地址"
                label-for="to_location"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="to_location"
                  size="sm"
                  v-model="allotoutboundinbound.to_location"
                  readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <!--  商品列表    -->
      <b-card header="商品列表">
        <stock-use-list
            ref="productList" :allotInboundId="id" :toWarehouseId="allotoutboundinbound.towarehouse_id"
            v-on:receiveItem="receiveItem" :isLook="isLook">
        </stock-use-list>
      </b-card>
      <b-col md="4">
        <modal-select
            :label=load_label
            type="input"
            v-on:change="fromChildren($event,['company_name_load','company_id_load'])"
            :params="['company_name_load','company_id_load']"
            :value="allotoutboundinbound.company_name_load"
            :warehouseId="allotoutboundinbound.towarehouse_id"
            modalName="装卸队"
            placeholder="点击选择装卸队"
        >
        </modal-select>
      </b-col>

      <!--    备注      -->
      <b-col md="12">
        <b-form-group
            label-cols="1"
            label-cols-lg="1"
            label="备注"
            label-for="memo"
            label-size="sm"
            class="mb-1"
        >
          <b-form-textarea
              id="memo"
              rows="3"
              max-rows="6"
              style="margin: 5px"
              v-model="allotoutboundinbound.memo"
          />
        </b-form-group>
      </b-col>


      <b-col cols="12">
        <b-form-group
            label-cols="1"
            label-cols-lg="1"
            label="附件"
            label-for="attachments"
            label-size="sm"
            class="mb-1"
        >
          <attachment-upload v-if="allotoutboundinbound.loaded" :theme="'files'"
                             :attachment_id="allotoutboundinbound.attachments"
                             :id="allotoutboundinbound.attachments"
                             :object_type="'allot_outbound_inbound'"
                             :object_id="allotoutboundinbound.inbound_id"
                             @change="onUploaded"
          />
        </b-form-group>
      </b-col>
      <!-- 操作 -->
      <b-col
          cols="12"
          class="mt-50"
      >
        <!--        <b-button
                    variant="primary"
                    class="mr-1"
                    @click="save()"
                >
                  保存
                </b-button>-->
        <b-button
            variant="outline-secondary"
            class="mr-1"
            @click="cancel"
            v-if="isLook===0"
        >
          取消
        </b-button>
        <b-button
            variant="outline-secondary"
            @click="submitInbound"
            v-if="isLook===0"
        >
          提交
        </b-button>
      </b-col>
    </b-form>
    <!--/ form -->
    <!--  loading  -->
    <div v-show="loadingModal"><xy-loading :placeholder="loadingPlaceholder"></xy-loading>  </div>
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import allotoutboundinboundStore from './allotoutboundinboundStore'
// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, isEmpty} from '@core/utils/filter'

import StockUseList from '@/views/apps/stock/allotoutboundinbound-stock/StockInbound'
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import ModalSelect from "@/views/components/modal/ModalSelect";
import {getUserData} from "@/auth/utils";
import axios from '@axios'
import XyLoading from "@/views/components/xy/XyLoading";
export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    AttachmentUpload,
    StockUseList,
    ModalSelect,
    XyLoading
  },
  data() {
    return {
      id: ref(0),
      allotoutboundinbound: ref({}),
      memoFile: "",
      receiveQtyItem: [],
      allot_outbound_id: 0,
      isLook: 0,
      user: {},
      load_label:'装卸队',
      loadingModal:false,
      loadingPlaceholder:"单据正在生成,请勿重复操作!"
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('allotoutboundinbound')) store.registerModule('allotoutboundinbound', allotoutboundinboundStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('allotoutboundinbound')) store.unregisterModule('allotoutboundinbound')
    })

    const edit = function () {
      store.dispatch('allotoutboundinbound/edit', {id: this.id}).then(res => {
        if (res.data.code == 0) {
          this.allotoutboundinbound = res.data.data
          this.allot_outbound_id = this.allotoutboundinbound.allot_outbound_id
          this.allotoutboundinbound.allot_time = toDate(this.allotoutboundinbound.allot_time)
          this.allotoutboundinbound.duty_name = this.user.full_name
          this.allotoutboundinbound.allot_type = getCodeLabel('allot_type', this.allotoutboundinbound.allot_type)
          this.selectId = true
          if (this.allotoutboundinbound.allot_type == '内部调拨' || this.allotoutboundinbound.allot_type == '寄售调拨') {
            if (isEmpty(this.allotoutboundinbound.company_name_load)) {
              this.load_label='装卸队*'
              //查询装卸队
              axios.post('/api/company/searchLoadTeam', {
                'allotFlag': 4,
                'warehouseId': this.allotoutboundinbound.towarehouse_id,
              }).then(res => {
                if (res.data.code === 0) {
                  const list = res.data.data.list
                  if (list.length > 0) {
                    this.allotoutboundinbound.company_name_load = list[list.length - 1].company_name
                    this.allotoutboundinbound.company_id_load = list[list.length - 1].company_id
                    this.$forceUpdate()
                  } else {
                    this.allotoutboundinbound.company_name_load = ''
                    this.allotoutboundinbound.company_id_load = ''
                  }
                }
              })
            }
          }
        } else {
          toast.error(res.data.data);
          this.$router.go(-1)
        }

      })
    }
    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.allotoutboundinbound[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }


    const view = function () {
      store.dispatch('allotoutboundinbound/view', {id: this.id}).then(res => {
        this.allotoutboundinbound = res.data.data
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      store.dispatch('allotoutboundinbound/save',
          this.allotoutboundinbound,
      ).then(res => {
        toast.success('数据已保存!')
        this.$router.push({name: 'apps-allotoutboundinbound-list', query: {id: this.allot_outbound_id}});
      })
    }

    const submitInbound = function () {
      // if (this.allotoutboundinbound.allot_type == '内部调拨' || this.allotoutboundinbound.allot_type == '寄售调拨'){
      //   if(isEmpty(this.allotoutboundinbound.company_name_load)){
      //     toast.error('请选择装卸队')
      //     return
      //   }
      // }
      let result = this.receiveQtyItem;
      let receiveQty = []
      let receiveStatus = []
      let receiveLocationId = []
      let inboundIdAll = []
      let yearAndMonths = []
      let batchNos = []

      result.forEach(item => {
        receiveQty.push(item['qty'])
        receiveStatus.push(item['status'])
        receiveLocationId.push(item['location_id'])
        inboundIdAll.push(item['id'])
        yearAndMonths.push(item['yearAndMonth'])
        batchNos.push(item['batchNo'])
      })
      if (receiveQty.length == 0) {
        if (confirm("没有修改过仓位,确定商品全部是良品，执行入库操作吗？")) {
          if (confirm("确认商品入库数量无误，执行入库操作？")) {
            this.loadingModal = true
            store.dispatch('allotoutboundinbound/submitInbound', {
              ...this.allotoutboundinbound,
              receiveQty: receiveQty.join(","),
              receiveStatus: receiveStatus.join(","),
              receiveLocationId: receiveLocationId.join(","),
              inboundIdAll: inboundIdAll.join(","),
              yearAndMonths: yearAndMonths.join(","),
              batchNos: batchNos.join(","),
            }).then(res => {
              this.loadingModal = false
              if (res.data.code === 0) {
                this.$router.push({name: 'apps-allotoutboundinbound-list', query: {id: this.allot_outbound_id}});
              } else {
                toast.error(res.data.data)
              }
            })
          }
        }
      } else {
        if (confirm("确认商品入库数量无误，执行入库操作？")) {
          this.loadingModal = true
          store.dispatch('allotoutboundinbound/submitInbound', {
            ...this.allotoutboundinbound,
            receiveQty: receiveQty.join(","),
            receiveStatus: receiveStatus.join(","),
            receiveLocationId: receiveLocationId.join(","),
            inboundIdAll: inboundIdAll.join(","),
            yearAndMonths:yearAndMonths.join(","),
            batchNos:batchNos.join(",")
          }).then(res => {
            this.loadingModal = false
            if (res.data.code === 0) {
              this.$router.push({name: 'apps-allotoutboundinbound-list', query: {id: this.allot_outbound_id}});
            } else {
              toast.error(res.data.data)
            }
          })
        }
      }

    }

    const onUploaded = function (id, attachment, result) {
      this.allotoutboundinbound.attachments = result
    }

    //获取到入库仓位 以及数量
    const receiveItem = function (val) {
      this.receiveQtyItem = val
    }

    return {
      edit,
      view,
      cancel,
      save,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      onUploaded,
      receiveItem,
      submitInbound,
      fromChildren
    }
  },
  created() {
    const userData = getUserData()
    this.user = userData
    this.id = this.$route.query.id || 0;
    this.isLook = this.$route.query.isLook || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
