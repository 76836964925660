<template>

  <div>

        <b-table
            ref="refListTable"
            class="position-relative"
            :items="searchALlList"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :order-by.sync="orderBy"
            show-empty
            empty-text="未找到记录"
            :order-desc.sync="isSortDirDesc"
            v-model="datalist"
        >

          <template #cell(code)="data">
            {{data.item.ext['code']}}
          </template>


          <template #cell(specification)="data">
            {{data.item.ext['specification']}}
          </template>

          <template #cell(receiveqty_good)="data">
            {{data.item.receiveqty_good}}
          </template>


          <template #cell(qty)="data">
              {{data.item.qty}}
          </template>

          <template #cell(receiveqty_bad)="data">
            <div :id="`extra_cost_subtotal-row-${data.item.id}`">
              {{data.item.receiveqty_bad }}
              <span v-if="data.item.receiveqty_bad > 1000000"> ...</span>
              <feather-icon icon="EditIcon"  @click="showReveiveBack(data.item)" v-if="isLook==0"/>
            </div>
          </template>

          <template #cell(id)="data">
            {{data.item.ext['id']}}
          </template>

          <template #cell(production_date)="data">
            {{data.item.ext['production_date']}}
          </template>

          <template #cell(warestatus)="data">
            {{ getCodeLabel("stock_status", data.item.warestatus) }}
          </template>

          <template #cell(productUnit)="data">
            {{ getCodeLabel("product_unit", data.item.ext['productUnit']) }}
          </template>


        </b-table>


    <!-- Table Container Card -->
    <b-modal
        id="receiveBackModal"
        ok-only
        ok-title="确认"
        cancel-title="取消"
        centered
        size="xl"
        :title="'调拨入库单仓位数量选择'"
        hide-footer
    >
      <AllotoutboundItemForm :type="type" :warehouseId="parseInt(toWarehouseId)" :condition="condition" v-on:table="fromChildren">

      </AllotoutboundItemForm>

    </b-modal>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BTabs,BTab,BFormGroup,BForm
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, toRefs } from '@vue/composition-api'
import {avatarText, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, second} from '@core/utils/filter'
import stockUseList from './stockUseList'
import stockStore from '../stockStore'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import { useToast } from 'vue-toastification/composition'
import axios from "@/libs/axios";
import AllotoutboundItemForm from "@/views/apps/allotoutboundinbounditem/AllotoutboundItemForm";
import allotoutboundinbounditemStore from "@/views/apps/allotoutboundinbounditem/allotoutboundinbounditemStore";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BTabs,
    BTab,
    flatPickr,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BForm,
    AllotoutboundItemForm,
  },
  data(){
    return {
      datalist:[],
      qty: 0,
      trueqty: 0,
      towarehouse_id: 0,
      condition: {},
      inbounditem_id:0,
      allotoutboundinbounditem: ref({}),
      receiveQtyItem:[],
      type:'allot_outbound'
    }
  },
  props:{
    allotInboundId :{
      type :Number,
      default: 0
    },

    toWarehouseId:{
      type :Number,
      default: 0
    },
    isLook:{
      type :Number,
      default: 0
    }
  },
  methods: {

  },

  setup(props) {
    const toast = useToast()

    // Register module
    if (!store.hasModule('stock')) store.registerModule('stock', stockStore)
    if (!store.hasModule('allotoutboundinbounditem')) store.registerModule('allotoutboundinbounditem', allotoutboundinbounditemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('stock')) store.unregisterModule('stock')
      if (store.hasModule('allotoutboundinbounditem')) store.unregisterModule('allotoutboundinbounditem')
    })

    const showReveiveBack = function (item) {

      this.inbounditem_id = item.inbounditem_id
      this.condition = item
      this.$bvModal.show('receiveBackModal')

    }

    const fromChildren = function (val) {
      if (val.length>0) {
        let inboundId = val[0]['id']
        let receiveQtyGood = 0
        let receiveQtyBad = 0
        for(let i = 0 ;i<this.receiveQtyItem.length;i++) {
          let inboundIdR = this.receiveQtyItem[i]['id']
          if (inboundId === inboundIdR) {
            this.receiveQtyItem.splice(i, 1)
            i-=1;
          }
        }
        val.forEach(item => {
          this.receiveQtyItem.push(item)
          if(item['status']==0){
            receiveQtyGood = receiveQtyGood+parseInt(item['qty'])
          }else{
            receiveQtyBad = receiveQtyBad+parseInt(item['qty'])
          }
        })
        this.$emit("receiveItem",this.receiveQtyItem)
        this.condition.receiveqty_bad=receiveQtyBad
        this.condition.receiveqty_good=receiveQtyGood
        store.dispatch('allotoutboundinbounditem/updateReceive',
            {id: this.inbounditem_id,
              receiveQtyGood:receiveQtyGood,
              receiveQtyBad:receiveQtyBad
            }
        ).then(res => {

        })
        this.$bvModal.hide('receiveBackModal')
      }
      // console.log("这是从子页面传来的", this.salesOrderItem)
    }


    const {
      searchALlList,
      stockMap,
      tableColumns,
      stockTable,
      batchTable,
      totalStockLock,
      totalStock,
      refetchData,
      wareHouseList,
      orderBy,
      isSortDirDesc,
      tableColumnBatch,
      batchList,

    } = stockUseList(
        {
          inboundId:props.allotInboundId,
          isLook: props.isLook
        }
    )


    return {
      searchALlList,
      batchList,
      stockMap,
      tableColumns,
      stockTable,
      batchTable,
      totalStockLock,
      totalStock,
      refetchData,
      tableColumnBatch,
      orderBy,
      isSortDirDesc,
      getCodeLabel,
      // Filter
      avatarText,
      showReveiveBack,
      //checksubmit,
      fromChildren,
      //checkQty
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
